import React from "react";
import { useContactModal } from "../Modal/contactModalContext";
import RevealAnimation from "../RevealAnimation";
import Button from "../Button";
import RightArrowWhite from "../../assets/Icons/accordian_white.svg";

function CtaCardSection({
  className = "",
  titleClassName = "",
  title,
  imageUrl,
  imgClassName = "",
  showBtn = false,
}) {
  const { setOpenModal } = useContactModal();
  return (
    <div className={`ctaCard ${className}`}>
      <div className="cta-card-container body-container">
        <div className="ctaLeft cta-left-2">
          <RevealAnimation
            component={"h3"}
            className={`title-header ctsTitleAnimation ${titleClassName}`}
            onClick={() => setOpenModal(true)}
          >
            {title}
          </RevealAnimation>
          {showBtn && (
            <RevealAnimation className={`cta-btn-wrap`}>
              <Button
                btnWrapClassName=""
                icon={
                  <img
                    className="effect-btn-svgicon"
                    src={RightArrowWhite}
                    alt={"Contact us"}
                  />
                }
                customClassName="info-view-resize-btn"
                color="primary"
                variant="contained"
                label={"Contact us"}
                onClick={() => setOpenModal(true)}
                tooltip="no"
              />
            </RevealAnimation>
          )}
        </div>
        <RevealAnimation
          animation="zoom-in"
          className={`ctaCardImage ${imgClassName}`}
        >
          <img src={imageUrl} alt="" className="ctaCard2" />
        </RevealAnimation>
      </div>
    </div>
  );
}

export default CtaCardSection;
